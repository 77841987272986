import { CountUp } from 'countup.js';
import IntervalsController from '../../utils/IntervalsController';
import React from 'react';

export default class MyCountUp extends React.Component {
  constructor(props = { id: '', start: 0, end: 100, suffix: '%' }) {
    super(props);
    this.counted = 0;
    this.wasHidden = true;
    this.countEveryTime = true;
    this.props = props;
    this.myIntervalNum = 0;
    this.newInterval = this.newInterval.bind(this);
  }

  newInterval() {
    return window.setInterval(() => {
      if (IntervalsController.checkVisible(document.getElementById(this.props.id))) {
        if (this.wasHidden) {
          window.clearInterval(this.myIntervalNum);
          this.wasHidden = false;

          if (this.countEveryTime || this.counted < 1) {
            let options = {
              useEasing: true,
              useGrouping: true,
              separator: '.',
              decimal: ',',
              suffix: this.props.suffix,
              prefix: '',
            };

            let demo = new CountUp(this.props.id, this.props.end, options);
            if (!demo.error) {
              demo.start(() => {
                this.myIntervalNum = this.newInterval();
              });
            } else {
              console.error(demo.error);
            }
            this.counted++;
          }
        }
      } else {
        // this.wasHidden = true;
      }
    }, 100);
  }

  componentDidMount() {
    this.myIntervalNum = this.newInterval();
  }

  componentWillUnmount() {
    window.clearInterval(this.myIntervalNum);
  }

  render() {
    return <em id={this.props.id}></em>;
  }
}
