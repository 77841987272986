import React, { Component } from 'react';
import Slider from 'react-slick';
import clsx from 'clsx';

import slick from './../../assets/3rdparty/slick/slick.css';
import slickTheme from './../../assets/3rdparty/slick/slick-theme.css';

import * as styles from './Carousel.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function showText() {
  if (typeof document !== 'undefined') {
    try {
      const slideTexts = document.querySelectorAll('.slick-slide p, .slick-slide h2, .slick-slide span');
      if (slideTexts) {
        for (let i = 0; i < slideTexts.length; i++) {
          slideTexts[i].className = '';
        }
      }
      if (document.querySelector('.slick-slide.slick-current h2')) {
        document.querySelector('.slick-slide.slick-current h2').className = styles.display;
      }
      if (document.querySelector('.slick-slide.slick-current p')) {
        document.querySelector('.slick-slide.slick-current p').className = styles.display;
      }
      document.querySelector('.slick-slide.slick-current span').className = styles.display;
    } catch (e) {
      // do nothing if there is an error here
      // it's most likely because this function is invoked with a timeout and the page may have already changed
      // to a different page (meaning the DOM elements are no longer available).
      // The clean solution would be to cancel the timeouts on page change but I am in a hurry right now and don't
      // have time to look into this.
    }
  }
}

export default class Carousel extends Component {
  onSliderClick = (event) => {
    if (event.clientX / window.innerWidth < 0.4) {
      this.slider.slickPrev();
    } else if (event.clientX / window.innerWidth > 0.6) {
      this.slider.slickNext();
    }
    return false;
  };

  componentDidMount() {
    setTimeout(() => {
      const elem = document.querySelector('.slick-list');
      if (elem) {
        elem.addEventListener('click', this.onSliderClick);
      }
    }, 200);
  }

  componentWillUnmount() {
    document.querySelector('.slick-list').removeEventListener('click', this.onSliderClick);
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplaySpeed: 5000,
      speed: 1000,
      autoplay: true,
      touchMove: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
      onInit: () => {
        setTimeout(showText, 200);
      },
      afterChange: function () {
        setTimeout(showText, 200);
      },
    };

    return (
      <div id="mainCarousel">
        <Slider
          ref={(slider) => (this.slider = slider)}
          {...settings}
          style={{ marginTop: -6, minHeight: 250 }}
          className="widewrap"
        >
          {this.props.slides.map((node) => {
            const {
              id,
              position,
              text_line_1,
              text_line_2,
              mobile_text_line_1,
              mobile_text_line_2,
              highlighted_line,
              text_color,
              image,
            } = node;

            const textClassNames = {};
            if (position === 'Links oben') {
              textClassNames[styles.upperLeft] = true;
            } else if (position === 'Links unten') {
              textClassNames[styles.lowerLeft] = true;
            } else if (position === 'Rechts oben') {
              textClassNames[styles.upperRight] = true;
            } else if (position === 'Rechts unten') {
              textClassNames[styles.lowerRight] = true;
            }

            let line1 = text_line_1.trim();
            let line2 = text_line_2.trim();

            if (mobile_text_line_1) {
              line1 = mobile_text_line_1.trim();
            }
            if (mobile_text_line_2) {
              line2 = mobile_text_line_2.trim();
            }

            let line1Highlight = true;
            let line2Highlight = false;

            if (highlighted_line) {
              line1Highlight = false;
              line2Highlight = true;
            }

            textClassNames[styles.textBlack] = text_color === 'Schwarz';
            textClassNames[styles.textWhite] = text_color === 'Weiss';

            textClassNames[styles.slideText] = true;

            return (
              <div className={clsx(styles.carousel)} key={id}>
                <GatsbyImage image={getImage(image)} alt={''} />
                {(line1 || line2) && (
                  <div className={styles.slideTextContainer}>
                    <div className={clsx(textClassNames)}>
                      {line1 && line1Highlight && <h2 dangerouslySetInnerHTML={{ __html: line1 }}></h2>}
                      {line1 && !line1Highlight && <p dangerouslySetInnerHTML={{ __html: line1 }}></p>}
                      {line2 && line2Highlight && <h2 dangerouslySetInnerHTML={{ __html: line2 }}></h2>}
                      {line2 && !line2Highlight && <p dangerouslySetInnerHTML={{ __html: line2 }}></p>}
                      <span />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
