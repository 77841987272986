import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './IFrameVideo.module.css';

IFrameVideo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
};
IFrameVideo.defaultProps = {
  className: '',
};

function IFrameVideo(props) {
  const classNames = props.className ? `${styles.IFrameVideo} ${props.className}` : `${styles.IFrameVideo}`;
  return (
    <div className={classNames}>
      <div className={styles.absoluteContainer}>
        <iframe
          // "https://www.youtube.com/embed/elEjqFHPjmw"
          src={props.src}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
export default IFrameVideo;
