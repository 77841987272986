export default class IntervalsController{
    

    static doSomethingOnVisible(id,callback){
        //intervals are identified by numbers
        const num = window.setInterval(()=>{
            if(IntervalsController.checkVisible(document.getElementById(id))){
                callback();
            };
        },100);
        IntervalsController.list.push(num);
    }
    static checkVisible (elm = document.documentElement) {
        try {
            var rect = elm.getBoundingClientRect();
            var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
        } catch (e) {
            // do nothing
        }
    }
}