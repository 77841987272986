import React from 'react';

import Button from '../Common/Button';

import * as styles from './Introduction.module.css';

export default function Introduction({ data }) {
  const { title, text, link, linkLabel } = data;
  return (
    <div className={styles.content}>
      <h2>{title}</h2>
      <p>{text}</p>
      <Button to={link} color="grey">
        {linkLabel}
      </Button>
    </div>
  );
}
