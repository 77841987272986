import React from 'react';

import { PageTitle } from '../Common/PageTitle';
import LogoCarousel from './LogoCarousel';
import Button from '../Common/Button';

import * as styles from './HerstellerTeaser.module.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';

function HerstellerTeaser({ manufacturers }) {
  const { t } = useTranslation('labels');
  return (
    <div className="widewrap">
      <PageTitle title={t('manufacturerTeaserTitle')} background="alternative" />
      <div className={styles.parallax}>
        <div className={styles.content}>
          <div className={styles.slider}>
            <LogoCarousel manufacturers={manufacturers} />
          </div>
        </div>
        <div>
          <Button to="/hersteller" color="blue" icon={'arrow-right'}>
            {t('goToManufacturerDetailPage')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default HerstellerTeaser;
