import React from 'react';
import Slider from 'react-slick';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';

import Link from 'Helpers/i18n/Link';

import * as styles from './LogoCarousel.module.css';
import { slug } from '@mangoart/gatsby-ui/helper/Slugger';

export default function LogoCarousel({ manufacturers }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoPlaySpeed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    lazyLoad: 'ondemand',
  };

  return (
    <Slider {...settings} className={clsx('logoCarousel', styles.carousel)}>
      {manufacturers.map(({ node }) => {
        const hasPng = !!node.logopng;
        const hasJpg = !!node.logojpg;
        const hasSvg = !!node.logosvg;

        const logoToUse = hasPng ? node.logopng : hasSvg ? node.logosvg : hasJpg ? node.logojpg : '';

        const linkToHersteller = '/hersteller';
        const extraPath = `/${slug(node.name)}`;

        return (
          logoToUse && (
            <div key={node.slug}>
              <Link to={linkToHersteller} extraPath={extraPath}>
                <img src={logoToUse.publicURL} alt={''} />
              </Link>
            </div>
          )
        );
      })}
    </Slider>
  );
}
