import React from 'react';
import Countup from './Countup';

import * as styles from './KeyFacts.module.css';

export default function KeyFacts({ data }) {
  const { fact1, fact2, fact3 } = data;

  return (
    <div className={styles.content}>
      <div>
        <p>
          {fact1.line1} <Countup id="haendlerCount" start="0" end={fact1.number} suffix="" /> {fact1.line2}
        </p>
      </div>
      <div>
        <p>
          {fact2.line1} <Countup id="maschinenCount" start="0" end={fact2.number} suffix="" /> {fact2.line2}
        </p>
      </div>
      <div>
        <p>
          {fact3.line1} <Countup id="flaechendeckungCount" start="0" end={fact3.number} suffix="" /> {fact3.line2}
        </p>
      </div>
    </div>
  );
}
