import React from 'react';

import Button from '../Common/Button';

import * as styles from './Boxes.module.css';
import LightBoxLink from '../LightBox/LightBoxLink';
import IFrameVideo from '../LightBox/IFrameVideo';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { slug as slugFn } from '@mangoart/gatsby-ui/helper/Slugger';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby';

export default function Boxes({ boxes, news }) {
  const { i18n } = useTranslation();
  const newsForBoxes = news.edges.slice(0, 2);

  return (
    <div className={styles.container}>
      {newsForBoxes.map((newsItem, index) => {
        const { teaserimage, title, teaser, customurl } = newsItem.node;
        const previewImage = getImage(teaserimage);
        const linkToArticle = customurl
          ? customurl
          : i18n.language === 'de'
          ? `/news/${slugFn(title)}`
          : `/en/news/${slugFn(title)}`;
        const actionLabelForNewsBox = 'Weiter lesen';
        return (
          <div className={styles.box} key={newsItem.node.id}>
            <Link to={linkToArticle}>
              <React.Fragment>
                <div className={styles.imageContainer}>
                  <GatsbyImage image={previewImage} alt={newsItem.node.title} />
                </div>
                <div className={styles.content}>
                  <h4>{title}</h4>
                  <p>{teaser || ' '}</p>
                  <Button
                    to={linkToArticle}
                    icon="arrow-right"
                    style={{ opacity: actionLabelForNewsBox && actionLabelForNewsBox.trim().length > 0 ? 1 : 0 }}
                  >
                    {actionLabelForNewsBox || 'empty button'}
                  </Button>
                </div>
              </React.Fragment>
            </Link>
          </div>
        );
      })}
      {boxes.slice(0, 2).map((node) => {
        const { id, link, image, title, teaser, alttext, actionlabel } = node;
        const absoluteLink = link.indexOf('://');
        const isYtLink = link.indexOf('https://www.youtube.com/watch?v=') > -1 ? true : false;

        const child = (
          <React.Fragment>
            <div className={styles.imageContainer}>
              <GatsbyImage image={getImage(image)} alt={alttext} />
            </div>
            <div className={styles.content}>
              <h4>{title}</h4>
              <p>{teaser || ' '}</p>
              <Button
                to={link}
                icon="arrow-right"
                style={{ opacity: actionlabel && actionlabel.trim().length > 0 ? 1 : 0 }}
              >
                {actionlabel || 'empty button'}
              </Button>
            </div>
          </React.Fragment>
        );
        return (
          <div className={styles.box} key={id}>
            {isYtLink ? (
              <LightBoxLink
                lightBoxContent={
                  <IFrameVideo src={`https://www.youtube.com/embed/${link.replace(/^.*v=(.*?)(&|$)/, '$1')}`} />
                }
              >
                {child}
              </LightBoxLink>
            ) : (
              <a href={link} target={absoluteLink ? '_blank' : ''}>
                {child}
              </a>
            )}
          </div>
        );
      })}
    </div>
  );
}
