import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import * as styles from './LightBox2.module.css';

export default class LightBox extends React.Component {
  static propTypes = {
    img: PropTypes.string,
    handleClose: PropTypes.func,
  };
  static defaultProps = {
    img: null,
    handleClose: null,
  };

  constructor(props) {
    super(props);
    this.props = props;
    this.el = document.createElement('div');
    this.state = {
      portal: this.makeLightBox(),
    };
  }
  componentDidMount() {
    document.documentElement.appendChild(this.el);
  }
  componentDidUpdate(pp, ps) {
    if (ps.portal === null) {
      this.setState({ portal: this.makeLightBox() });
    }
  }
  componentWillUnmount() {
    document.documentElement.removeChild(this.el);
  }
  render() {
    return this.state.portal;
  }
  makeLightBox = () => {
    document.documentElement.style.position = 'fixed';

    return ReactDOM.createPortal(
      <div className={styles.LightBox}>
        <div className={styles.background} onClick={this.closeLightBox} />
        <div className={styles.closeContainer}>
          <div className={styles.close} onClick={this.closeLightBox}>
            {getCloseIcon()}
          </div>
        </div>
        <div className={styles.centerMaxContainer}>{this.props.children}</div>
      </div>,
      this.el,
    );
  };
  closeLightBox = (e) => {
    document.documentElement.style.position = 'relative';
    if (this.props.handleClose) {
      this.props.handleClose(e);
    } else {
      this.setState({ portal: null });
    }
    e.preventDefault();
  };
}
function getCloseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" />
    </svg>
  );
}
