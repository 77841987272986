import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import * as styles from './LightBoxLink.module.css';
import LightBox2 from './LightBox2';

class LightBoxLink extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    lightBoxContent: PropTypes.any,
  };
  static defaultProps = { className: '' };
  blockEncapsulatedEventOnce = false;
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isOpen: false,
    };
  }
  handleOpen = (e) => {
    e.preventDefault();
    if (!this.state.isOpen && !this.blockEncapsulatedEventOnce) {
      this.setState({ isOpen: true });
    }
    this.blockEncapsulatedEventOnce = false;
  };
  handleClose = (e) => {
    e.preventDefault();
    this.setState({ isOpen: false });
    this.blockEncapsulatedEventOnce = true;
  };
  render() {
    const classNames = clsx(this.props.className, styles.LightBoxLink);
    return (
      <a className={classNames} onClick={this.handleOpen}>
        {this.props.children}
        {this.state.isOpen && <LightBox2 handleClose={this.handleClose}>{this.props.lightBoxContent}</LightBox2>}
      </a>
    );
  }
}
export default LightBoxLink;
