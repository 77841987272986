// extracted by mini-css-extract-plugin
export var carousel = "Carousel-module--carousel--Zs1wX";
export var display = "Carousel-module--display--8iip8";
export var lowerLeft = "Carousel-module--lowerLeft--qKd7z";
export var lowerRight = "Carousel-module--lowerRight--c9bdo";
export var slideText = "Carousel-module--slideText--rojCC";
export var slideTextContainer = "Carousel-module--slideTextContainer--UWPTn";
export var textBlack = "Carousel-module--textBlack--jObud";
export var textWhite = "Carousel-module--textWhite--+kB9q";
export var upperLeft = "Carousel-module--upperLeft--iZkFX";
export var upperRight = "Carousel-module--upperRight--h2glm";